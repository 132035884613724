import classNames from 'classnames';

import { Body2, ButtonStyles, Typography } from '@pumpkincare/shared/ui';

import styles from './plan-whats-covered.css';

function PlanWhatsCovered({ coverageDetails, onCompareClick }) {
  return (
    <div className={styles.container}>
      <h3 className={Typography.h3}>{coverageDetails.header}</h3>

      <h4 className={classNames(styles.h4, Typography.h4)}>
        An Extensive List of Accidents & Illnesses:
      </h4>

      <div className={styles.iconsSection}>
        {coverageDetails.insurance.map((item, title) => (
          <div key={title} className={styles.iconItem}>
            <div className={styles.iconContainer}>
              <img src={item.imageUrl} alt='' />
            </div>

            <div>
              <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

              <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.iconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails?.unique_insurance?.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>

        <div className={styles.compareCTA}>
          <img src='/assets/images/Favicon_Blue_Clues.svg' alt='' />

          <span>
            <span className={classNames(Typography.body2, Typography.bold)}>
              Pumpkin plans cover these, but some insurances don’t!
            </span>

            <span
              className={classNames(styles.link, ButtonStyles.cta)}
              onClick={onCompareClick}
              role='button'
            >
              Compare
              <img
                src='/assets/images/iconImages/arrow-right-blue-clues.svg'
                alt=''
              />
            </span>
          </span>
        </div>
      </div>

      <h4 className={classNames(Typography.h4, styles.sectionTitle)}>
        Eligible Vet Care for Accidents & llnesses:
      </h4>

      <div className={styles.iconsSection}>
        {coverageDetails.vet.map((item, title) => (
          <div key={title} className={styles.iconItem}>
            <div className={styles.iconContainer}>
              <img src={item.imageUrl} alt='' />
            </div>

            <div>
              <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

              <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.iconsWrapper}>
        <div className={styles.iconsSection}>
          {coverageDetails?.unique_vet?.map((item, title) => (
            <div key={title} className={styles.iconItem}>
              <div className={styles.iconContainer}>
                <img src={item.imageUrl} alt='' />
              </div>

              <div>
                <Body2 isBold dangerouslySetInnerHTML={{ __html: item.title }} />

                <Body2 dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>

        <div className={styles.compareCTA}>
          <img src='/assets/images/Favicon_Blue_Clues.svg' alt='' />

          <span>
            <span className={classNames(Typography.body2, Typography.bold)}>
              Pumpkin plans cover these, but some insurances don’t!
            </span>

            <span
              className={classNames(styles.link, ButtonStyles.cta)}
              onClick={onCompareClick}
              role='button'
            >
              Compare
              <img
                src='/assets/images/iconImages/arrow-right-blue-clues.svg'
                alt=''
              />
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

PlanWhatsCovered.defaultProps = {
  coverageDetails: {
    insurance: [],
    header: '',
    unique_insurance: [],
  },
};

export default PlanWhatsCovered;
