import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { usePetPlanData } from '@pumpkincare/plans';
import {
  getPetPolicy,
  getQuoteActivePet,
  getQuoteId,
  getQuoteVetId,
  useMutatePetPolicy,
  useQuote,
} from '@pumpkincare/quotes';
import { DOG, UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '@pumpkincare/shared';
import {
  Body2,
  CollapsiblePanel,
  LegalBody,
  Typography,
} from '@pumpkincare/shared/ui';

import { getMediaIsMdUp } from '../../../../app-shell';
import { getIdentityId } from '../../../../identity';
import { getQuotesActiveId } from '../../../../quotes/selectors';
import InsurancePicker from './insurance-picker';

import styles from './picker.css';

function Picker({ onAddToCart, buttonText = 'Add to Cart', headerSplit, image }) {
  const isDesktop = useSelector(getMediaIsMdUp);

  const { profileModule, punks594BreedSpeciesOnPlanPage } = useFlags();

  const activeId = useSelector(getQuotesActiveId);
  const identityId = useSelector(getIdentityId);

  const { data: quoteData } = useQuote();
  const quoteId = getQuoteId(quoteData);
  const activePet = getQuoteActivePet(quoteData)(activeId);
  const vetId = getQuoteVetId(quoteData);
  const { mutate: mutatePetPolicy } = useMutatePetPolicy();

  const heroImage = `/assets/images/photos/${getHeroImage(
    activePet?.petAge,
    activePet?.petBreedSpecies
  )}`;

  const { isReady, data: petPlanData } = usePetPlanData({
    petId: activePet.id,
    identityId,
    vetId,
  });

  const {
    activeValues,
    infoContainer: { whyDoWeRecommend: { lineItems } = {} } = {},
  } = petPlanData;

  const profileImage =
    !punks594BreedSpeciesOnPlanPage && profileModule
      ? `/assets/images/photos/plan-selection/${getProfileImage(
          activePet?.petAge,
          activePet?.petBreedSpecies
        )}`
      : image;

  function getProfileImage(petAge, petBreedSpecies) {
    if (petBreedSpecies === DOG) {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-puppy.png'
        : 'profile-image-dog.png';
    } else {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'profile-image-kitten.png'
        : 'profile-image-cat.png';
    }
  }

  function getHeroImage(petAge, petBreedSpecies) {
    if (petBreedSpecies === DOG) {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'photo-puppy.png'
        : 'photo-dog.png';
    } else {
      return petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS
        ? 'photo-kitten.png'
        : 'photo-cat.png';
    }
  }

  function handleInsuranceChange({ key, value }) {
    mutatePetPolicy({
      pet: { ...activePet, [key]: value },
    });
  }

  useEffect(() => {
    getPetPolicy(quoteId, activePet.id)
      // mutate pet policy selection with recommended options right away
      .then(() => mutatePetPolicy({ pet: { ...activePet, ...activeValues } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const titleClassName = classNames({
    [styles.title]: !profileModule,
    [styles.titleProfile]: profileModule,
  });

  return (
    <div className={styles.root}>
      {profileModule ? (
        <>
          <div className={styles.pickerSectionGrid}>
            <div className={styles.headerGrid}>
              <span className={classNames(Typography.h3, styles.headerStart)}>
                {headerSplit.start}
              </span>{' '}
              <span className={classNames(Typography.h4, styles.headerSplit)}>
                {headerSplit.adjectives}
              </span>
            </div>

            <div className={styles.imagesGrid}>
              <div className={styles.imagesWrapper}>
                <img
                  src={profileImage}
                  alt=''
                  className={styles.profileModuleImage}
                />

                <div className={styles.costBlurbImage}>
                  <img
                    src='/assets/images/iconImages/piggy-bank.png'
                    alt=''
                    className={styles.piggyImg}
                  />

                  <Body2>
                    Your {activePet.petBreedSpecies.toLowerCase()}’s breed, age & ZIP
                    + the reimbursement rate, annual limit & deductible you choose
                    all factor into your cost!
                  </Body2>
                </div>
              </div>
            </div>

            <div className={styles.pickerCompGrid}>
              <div className={styles.pickerContainer}>
                {isReady ? (
                  <InsurancePicker
                    onAddToCart={onAddToCart}
                    buttonText={buttonText}
                    petPlanData={petPlanData}
                    onChange={handleInsuranceChange}
                    pet={activePet}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h3 className={(Typography.h3, titleClassName)}>{headerSplit.start}</h3>

          <div className={styles.gridContainer}>
            <div className={styles.collapsibleGrid}>
              {isReady ? (
                <CollapsiblePanel
                  defaultOpen={isDesktop}
                  titleIcon='/assets/images/iconImages/design-star.png'
                  title='What we recommend.'
                  subTitle='See Why'
                  classes={{
                    title: styles.collapsibleTitle,
                    subtitle: styles.collapsibleSubTitle,
                    collapseButton: styles.collapseButton,
                    collapseContainer: styles.collapseContainer,
                  }}
                >
                  {lineItems.map(({ item, reason }) => {
                    return (
                      <Fragment key={item}>
                        <LegalBody className={styles.lineItem}>
                          <b className={styles.lineItemMain}>{item}</b> {reason}
                        </LegalBody>
                      </Fragment>
                    );
                  })}
                </CollapsiblePanel>
              ) : null}
            </div>

            <div className={styles.imageGrid}>
              <div className={styles.boxImgContainer}>
                <img
                  data-testid={'hero-image'}
                  src={heroImage}
                  className={styles.boxImg}
                  alt='pet box'
                />
              </div>
            </div>

            <div className={styles.pickerGrid}>
              <div className={styles.pickerContainer}>
                {isReady ? (
                  <InsurancePicker
                    onAddToCart={onAddToCart}
                    buttonText={buttonText}
                    petPlanData={petPlanData}
                    onChange={handleInsuranceChange}
                    pet={activePet}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

Picker.defaultProps = {
  buttonText: 'Add to Cart',
  headerSplit: {},
  image: '',
};

Picker.propTypes = {
  buttonText: PropTypes.string,
  headerSplit: PropTypes.shape({
    start: PropTypes.string,
    adjectives: PropTypes.string,
  }),
  image: PropTypes.string,
};
export default Picker;
