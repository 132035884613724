import { Fragment } from 'react';

import { Typography } from '@pumpkincare/shared/ui';

import styles from './plan-how-it-works.css';

function PlanHowItWorks({ howItWorks }) {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <span className={Typography.h3}>{howItWorks.header}</span>

        <div className={styles.bodySection}>
          {howItWorks.items.map((item, index) => {
            return (
              <Fragment key={item.copy}>
                <div className={styles.sectionItem}>
                  <div className={styles.iconContainer}>
                    <img src={item.imageUrl} alt='' />
                  </div>

                  <span>{item.copy}</span>
                </div>

                {howItWorks.items.length === index + 1 ? null : (
                  <img
                    src='/assets/images/long-arrow.svg'
                    alt=''
                    className={styles.arrow}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
      </div>

      <div className={styles.footerImageContainer}>
        <div className={styles.bottomWave} />
      </div>
    </div>
  );
}

export default PlanHowItWorks;
